/* COLORS
------------------------------------------------------- */
text = #777
red = #d54f5b
grey = #787b80


/* HTML, BODY
------------------------------------------------------- */
*
  margin 0px
  border 0px
  padding 0px
  box-sizing border-box

body,
html
  height 100%

body
  width 100%
  position relative
  right 0
  font-family 'Open Sans', sans-serif
  font-size 105%
  font-weight 400
  line-height 1.7
  background-color white


/* GENERALI
------------------------------------------------------- */
em
  font-style italic
p
  color text
  margin-bottom 1.6em

h1
h2
  font-family 'Crimson Text', serif
  font-weight 400
  font-style italic
  transform skew(-8deg)
  line-height 0.8em
  color red
  margin 1.2em 0 0.25em 0
  &:first-of-type
    margin-top 0
h1
  font-size 4em
  @media screen and (max-width 1000px)
    font-size 3.5em
  @media screen and (max-width 800px)
    font-size 3.1em
  @media screen and (max-width 600px)
    font-size 2.8em
h2
  font-size 3em
  @media screen and (max-width 1000px)
    font-size 2.7em
  @media screen and (max-width 800px)
    font-size 2.4em
  @media screen and (max-width 600px)
    font-size 2em
strong
  font-weight 700
  
a
a:hover
a:active
a:focus
  color text
  outline none
  transition color 0.2s ease
a
  text-decoration underline
a:hover
li.active
  color red
  
.left
  float left
.right
  float right

li
  list-style-position inside
  color text

ol
  color text
  li
    margin-bottom 0.8em
    &:last-of-type
      margin-bottom 0

hr
  border-bottom 1px dotted red
  display block
  padding-top 35px


/* WRAPPER
------------------------------------------------------- */
.wrapper
  min-height 100%
  width 100%
  position relative
  overflow hidden


/* HEADER
------------------------------------------------------- */
header
  width 100%
  height 100px
  min-height 100px
  background-color white
  display block
  align-items flex-start
  padding 15px 40px
  position fixed
  top 0
  left 0
  overflow hidden
  box-shadow 0 -2px 10px rgba(0, 0, 0, 0.5)
  z-index 10
  transition all 0.5s linear
  @media all and (max-width 1040px)
    padding 15px 20px
  /*@media all and (max-width 550px)
    height 80px
    min-height 80px
    padding 10px 20px*/
  a
    text-decoration none
    z-index 50
    .logo
      width calc((100% - 80px) - 120px)
      height 70px
      display flex
      background-size contain
      background-repeat no-repeat
      background-position 0% 100%
      white-space pre-line
      transition all 0.5s linear
      @media all and (max-width 1040px)
        width calc((100% - 40px) - 120px)
      img
        height 100%
        width auto
      h1
        font-size 2em
        display flex
        align-self flex-end
        margin-bottom 0
        border 0px
        padding 0px
        transition all 0.5s linear
        @media all and (max-width 570px)
          font-size 1.5em
        @media all and (max-width 470px)
          font-size 1.2em
        @media all and (max-width 370px)
          font-size 1em
        .title-cookie &
          font-size 2.8em
          position relative
          top 6px
          @media all and (max-width 570px)
            font-size 2.4em
            top 8px
          @media all and (max-width 470px)
            font-size 1.9em
            top 4px
          @media all and (max-width 370px)
            font-size 1.4em
            top 3px
        .title-dancing &
          font-size 2.4em
          position relative
          top 3px
          @media all and (max-width 570px)
            font-size 2.2em
          @media all and (max-width 470px)
            font-size 1.7em
            top 0px
          @media all and (max-width 370px)
            font-size 1.2em
  .hamburger
    outline none
    position absolute
    top 38px
    right 38px
    padding 0
    transition all 0.5s linear
    @media all and (max-width 1040px)
      right 18px
    .hamburger-box
      outline none
      .hamburger-inner
        margin-top 6px
        height 2px
      .hamburger-inner::before,
      .hamburger-inner::after
        outline none
        height 2px
  .menu
    margin-top 30px
    ul
      list-style none
      li
        display flex
        flex-direction column
        text-align right
        a
          text-transform uppercase
          font-size 0.95em
          padding 7px 0 0 30px
          transition all 0.1s linear
          &:hover
            // padding 7px 20px 0 30px
            transition all 0.1s linear
  .languages
    position absolute
    top 42px
    right 93px
    z-index 50
    transition all 0.5s linear
    @media all and (max-width 1040px)
      right 63px
    &.moved
      right 33px
      @media all and (max-width 1040px)
        right 13px
    ul
      display flex
      flex-direction row
      li
        height 10px
        position relative
        top 8px
        list-style none
        border-right 1px solid text
        &:last-child
          border-right 0px
        &.active
          a
            color red
        a
          position relative
          top -11px
          font-size 0.8em
          text-transform uppercase
          padding 0 7px
          text-decoration none
          &:hover
            text-decoration none
  &.smaller
    height 70px
    min-height 70px
    padding 10px 40px
    @media all and (max-width 1040px)
      padding 10px 20px
    a
      .logo
        height 50px
        transition all 0.5s linear
        h1
          font-size 1.8em
          transition all 0.5s linear
          @media all and (max-width 570px)
            font-size 1.3em
          @media all and (max-width 470px)
            font-size 1em
          @media all and (max-width 370px)
            font-size 0.8em
          .title-cookie &
            font-size 2.2em
            @media all and (max-width 570px)
              font-size 2em
            @media all and (max-width 470px)
              font-size 1.7em
            @media all and (max-width 370px)
              font-size 1.2em
          .title-dancing &
            font-size 2em
            @media all and (max-width 570px)
              font-size 1.8em
            @media all and (max-width 470px)
              font-size 1.5em
            @media all and (max-width 370px)
              font-size 1em
    .hamburger
      top 20px
      transition all 0.5s linear
    .languages
      top 22px
      transition all 0.5s linear
  &.is-active
    height auto
    min-height 80px
    display block
    padding-bottom 30px
    transition all 0.5s linear
    &.smaller
      min-height 50px


/* CONTENT
------------------------------------------------------- */
.content
  width 100%
  height 100%
  position relative
  top 100px
  padding-bottom 240px   // Altezza minima footer + padding
  @media all and (max-width 768px)
    padding-bottom 380px   // Alzezza minima footer + padding
  .landscape
    display flex
    align-items flex-end
    justify-content flex-start
    width 100%
    height 60vh
    position relative
    background-size cover
    background-repeat no-repeat
    @media all and (max-width 1400px)
      height 56vh
    @media all and (max-width 1200px)
      height 51vh
    @media all and (max-width 1000px)
      height 47vh
    @media all and (max-width 800px)
      height 43vh
    @media all and (max-width 600px)
      height 39vh
    .claim
      width auto
      max-width calc(100% - 80px)
      font-family 'Crimson Text', serif
      font-weight 400
      font-style italic
      transform skew(-8deg)
      font-size 2.75em
      line-height 1em
      p
        color white
        margin 0 0 20px 20px
        text-shadow 0px 0px 5px black
      @media all and (max-width 1040px)
        max-width calc(100% - 40px)
      @media all and (max-width 1000px)
        font-size 2.5em
      @media all and (max-width 800px)
        font-size 2.25em
      @media all and (max-width 600px)
        font-size 2em
      @media all and (max-width 400px)
        font-size 1.75em
    img.scroll-bottom
      width 64px
      padding 10px
      position absolute
      bottom 20px
      left calc((100% - 60px) / 2)
      right calc((100% - 60px) / 2)
      cursor pointer
      @media all and (max-width 1000px)
        display none

    &.home
      display flex
      align-items center
      justify-content center
      height calc(100vh - 80px)
      line-height 2.7em
      padding-bottom 0
      @media all and (max-width 1400px)
        height 75vh
      @media all and (max-width 1200px)
        height 71vh
      @media all and (max-width 1000px)
        height 67vh
      @media all and (max-width 800px)
        height 64vh
      @media all and (max-width 600px)
        height 60vh
      @media all and (max-width 400px)
        height 54vh
      .claim
        font-size 4.5em
        text-align center
        p
          color darken(red, 13)
          text-shadow 0px 0px 5px lighten(black, 30)
        @media all and (max-width 1000px)
          font-size 4em
        @media all and (max-width 800px)
          font-size 3.5em
        @media all and (max-width 600px)
          font-size 3em
        @media all and (max-width 400px)
          font-size 2.5em
        
  .text
    width 100%
    max-width 1040px
    display block
    overflow hidden
    margin 0 auto
    padding 40px 20px 30px
    p:last-of-type
      margin-bottom 0.4em
    &.light-background
      background-color grey-background
      padding 100px 0 120px
      border-top 1px solid #d0d0d0
      border-bottom 1px solid #d0d0d0
      box-shadow 0px 0px 6px rgba(0, 0, 0, 0.3)
    img
      width 100%
      height auto
    .gallery
      display block
      background-color grey-background
      overflow hidden
      padding 10px
      margin 10px 0
      h1
        margin-bottom 0.35em
      h2
        margin-bottom 0.35em
      ol
        list-style-position inside
      &:first-of-type
        margin-top 50px
      .images
        width 100%
        max-width 1000px
        padding 0
        margin 0 auto
        margin-top 5px
        flex-wrap wrap
        a
          lost-column 1/7 7
          margin-bottom 0px
          @media all and (max-width 950px)
            lost-column 1/6 6
          @media all and (max-width 775px)
            lost-column 1/5 5
          @media all and (max-width 625px)
            lost-column 1/4 4
          @media all and (max-width 450px)
            lost-column 1/3 3
          img
            width 100%
            max-width 200px
            opacity 0.75
            transition all 0.5s linear
          &:hover
            img
              opacity 1
              transition all 0.5s linear
  .picture
    width 100%
    max-width 1000px
    height 100%
    padding-bottom 27%
    background-size cover
    background-repeat no-repeat
    margin 40px auto 30px
    @media all and (max-width 1040px)
      background-attachment local
      padding-bottom 50%
    @media all and (max-width 480px)
      background-attachment local
      padding-bottom 60%
    @media all and (max-width 375px)
      background-attachment local
      padding-bottom 70%
  .map-canvas
    padding-bottom 40%
    height auto
    background-color #ddd
    > div
      display block
      height 100%
    @media all and (max-width 1040px)
      padding-bottom 40%
    @media all and (max-width 768px)
      padding-bottom 50%
    @media all and (max-width 480px)
      padding-bottom 60%
    @media all and (max-width 375px)
      padding-bottom 70%

/* FOOTER
------------------------------------------------------- */
footer
  width 100%
  height auto
  min-height 100px
  display flex
  flex-direction row
  justify-content space-between
  align-items center
  position absolute
  left 0
  bottom 0
  padding 20px 40px
  background-color white
  box-shadow 0 2px 10px rgba(0, 0, 0, 0.5)
  @media all and (max-width 1040px)
    padding 20px
  @media all and (max-width 768px)
    min-height 240px
    flex-direction column
  .contact
    font-size 0.8em
    line-height 1.4em
    text-align left
    @media all and (max-width 768px)
      text-align center
    p
      margin-bottom 0
  .social
    // display inline-block
    display none
    a
      height 100%
      padding 2px
      img
        height 35px
        opacity 0.8
        transition all 0.2s linear
      &:hover
        img
          opacity 1
          transition all 0.2s linear
  .credits
    height auto
    font-size 0.8em
    line-height 1.4em
    text-align right
    @media all and (max-width 768px)
      text-align center
    p
      margin-bottom 0
    a
      height 100%
      opacity 0.8
      transition all 0.2s linear
      &:hover
        opacity 1
        transition all 0.2s linear
      img
        width auto
        height 60px
        @media all and (max-width 768px)
          width auto
          height 16px


/* SINGLE CLASSES
------------------------------------------------------- */
.empty-80
  width 100%
  height 80px
  display block
  overflow hidden
.hide-lg
  @media all and (min-width 769px)
    display none
.hide-sm
  @media all and (max-width 768px)
    display none